<template>
  <div class="story"
       :style="`height: calc(100vh - ${GET_HEAD_HEIGHT}px);`"
       v-touch:swipe.left.prevent="nextStoriesSet"
       v-touch:swipe.right.prevent="prevStoriesSet"
       v-touch:touchhold.prevent="pauseStory"
       v-touch:longtap.prevent="releaseStory"
  >
    <div class="story__dots" :style="style">
      <div class="story__dot"
           v-for="(part, count) in storyParts"
           :key="part.id"
           :style="[
                count === index ? progress : '',
                count < index ? { background: 'var(--rir-white)' } : ''
              ]"
      ></div>
    </div>

    <transition-group :name="direction ? 'story-left' : 'story-right'" tag="div" class="story__wrapper">
      <div class="story__item"
           v-for="(story, count) in storyParts"
           :key="story.id"
           v-show="count === index"
      >
        <img :src="`${story.image.replace('small', 'large')}`"
             class="story__image" alt="">

        <div class="story__content">
          <r-row class="px-4">
            <r-col :cols="{ widest: 12, wide: 12, middle: 12, narrow: 12 }">
              <h1 v-line-clamp:44="3" class="story__title cheddar">
                {{ story.title }}
              </h1>

              <div v-line-clamp:24="4" class="story__description parmigiano" style="word-break: normal;">
                {{ story.description }}
              </div>
            </r-col>
          </r-row>
        </div>

        <r-fader/>
      </div>
    </transition-group>

    <div class="story__controls">
      <div class="story__controls-prev" @click="prev"></div>
      <div class="story__controls-next" @click="next"></div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "StoryView",

  computed: {
    ...mapGetters(["GET_STORIES", "GET_HEAD_HEIGHT",]),
    
    initialCounterNumber() {
      return this.$currentConfig.mainScreen.stories.storyCounter ? (this.$currentConfig.mainScreen.stories.storyCounter * 20) : 100;
    },

    currentStory() {
      return this.GET_STORIES?.find(item => item.id === parseInt(this.$route.query.id));
    },

    storyParts() {
      return this.currentStory?.imgs || [];
    },

    progress() {
      const percent = (this.counter / this.initialCounterNumber) * 100;
      return {
        background:
            `linear-gradient(to left, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.12) ${percent}%, var(--rir-white) ${percent}%, var(--rir-white) 100%)`
      }
    },

    style() {
      return {
        'grid-template-columns': `repeat(${this?.storyParts?.length || 0}, 1fr)`
      }
    },

    currentStoryIndex() {
      return this.GET_STORIES?.findIndex(item => parseInt(item.id) === parseInt(this.$route.query.id));
    },
    
    isPrevSetPossible() {
      return this?.currentStoryIndex > 0;
    },
    
    isNextSetPossible() {
      return this?.currentStoryIndex < this.GET_STORIES?.length - 1;
    },
  },

  data() {
    return {
      counter: this.initialCounterNumber,
      index: 0,
      timeout: null,
      direction: true,
      storyIndex: 0,
    }
  },

  watch: {
    counter(count) {
      if (count === 0) {
        if (this.timeout) clearTimeout(this.timeout);
        this.next();
      }
    }
  },
  
  created() {
    this.counter = this.initialCounterNumber;
  },

  mounted() {
    this.countDown();
  },

  destroyed() {
    if (this.timeout) clearTimeout(this.timeout);
  },

  methods: {
    countDown() {
      if (this.counter) {
        this.timeout = setTimeout(() => {
          --this.counter;
          this.countDown();
        }, 50);
      }
    },

    next() {
      if (this.timeout) clearTimeout(this.timeout);
      this.direction = true;

      if (this.storyParts.length > 1) {
        this.counter = this.initialCounterNumber;

        if (this.index < this.storyParts.length - 1) {
          this.index += 1;
          this.countDown();
        } else {
          this.index = 0;
          this.countDown();
        }
      } else {
        this.index = 0;
        this.counter = this.initialCounterNumber;
        this.countDown();
      }
    },

    prev() {
      if (this.timeout) clearTimeout(this.timeout);
      this.direction = false;

      if (this.storyParts.length > 1) {
        this.counter = this.initialCounterNumber;

        if (this.index >= 1) {
          this.index -= 1;
          this.countDown();
        } else {
          this.index = this.storyParts.length - 1;
          this.countDown();
        }
      } else {
        this.index = this.storyParts.length - 1;
        this.counter = this.initialCounterNumber;
        this.countDown();
      }
    },

    nextStoriesSet() {
      if (!this.isNextSetPossible) {
        return false;
      }

      this.direction = true;

      let nextIndex = this.currentStoryIndex + 1
      if (nextIndex > this.GET_STORIES?.length - 1) {
        nextIndex = 0;
      }

      this.$router.push({ query: { id: this.GET_STORIES[nextIndex].id }});

      this.counter = this.initialCounterNumber;
      this.index = 0;
    },

    prevStoriesSet() {
      if (!this.isPrevSetPossible) {
        return false;
      }

      this.direction = false;

      let prevIndex = this.currentStoryIndex - 1;
      if (prevIndex < 0) {
        prevIndex = this.GET_STORIES?.length - 1;
      }

      this.$router.push({ query: { id: this.GET_STORIES[prevIndex].id }});

      this.counter = this.initialCounterNumber;
      this.index = 0;
    },

    pauseStory() {
      if (this.timeout) clearTimeout(this.timeout);
    },

    releaseStory() {
      this.countDown();
    }
  }
}
</script>

<style lang="scss">
.story {
  width: 100%;

  position: relative;

  overflow: hidden;

  &__dots {
    position: absolute;

    top: 0;
    right: 0;
    left: 0;

    display: grid;
    grid-gap: 16px;

    padding: 24px;
    margin-bottom: 24px;

    z-index: 10;
  }

  &__dot {
    height: 6px;

    background: rgba(255, 255, 255, 0.12);
    border-radius: 4px;

    z-index: 2;
  }

  &__content {
    position: relative;
    z-index: 10;
  }

  &__item {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    overflow: hidden;

    padding-bottom: 176px;

    z-index: 9;
  }

  &__image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    touch-action: none;
    pointer-events: none;
  }

  &__badges {
    margin-bottom: 16px;
  }

  &__badge {
    margin-top: 8px;
    margin-right: 8px;
  }

  &__title {
    color: var(--rir-white);
    word-break: normal!important;
    margin-bottom: 24px;
  }

  &__description {
    color: var(--rir-white);
    word-break: normal!important;
  }

  &__controls {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 112px;

    display: flex;

    z-index: 12;

    &-prev {
      width: 50%;
      height: 100%;
    }

    &-next {
      width: 50%;
      height: 100%;
    }
  }

  .story-left-enter-active,
  .story-left-leave-active,
  .story-right-enter-active,
  .story-right-leave-active {
    transition: all 3s cubic-bezier(0.19, 1, 0.22, 1);
  }

  .story-left-enter,
  .story-right-leave-to {
    transform: translateX(100%);
  }

  .story-right-enter,
  .story-left-leave-to {
    transform: translateX(-100%);
  }

  .story-left-leave-to,
  .story-right-leave-to {
    &:after {
      content: '';

      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;

      background: rgba(0, 0, 0, .4);

      z-index: 12;
    }
  }

  .story-left-enter {
    transform: translateX(100%);
  }

  .story-right-enter {
    transform: translateX(-100%);
  }

}
</style>
